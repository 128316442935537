
/**
 * Module dependencies.
 */

import { resolve } from 'url';

/**
 * Export `absoluteUrlResolver`.
 */

export function absoluteUrlResolver(path: string): string {
  return resolve(process.env.NEXT_PUBLIC_BASE_URL as string, path);
}
