
/**
 * Module dependencies.
 */

import {
  Box,
  Svg,
  color,
  isExternalUrl,
  media,
  units,
  useBreakpoint
} from '@untile/react-components';

import { ContactPage } from 'src/types/contact-page';
import { Type } from 'src/components/core/typography';
import { theme } from 'styled-tools';
import { useMenuFooterLinks } from 'src/core/content-config/footer';
import { useRouter } from 'next/router';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import React, { Fragment, ReactElement, useMemo } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import dstGroupLogo from 'src/assets/svg/dst-group.svg';
import facebookLogo from 'src/assets/svg/facebook.svg';
import linkedinLogo from 'src/assets/svg/linkedin.svg';
import size from 'lodash/size';
import styled from 'styled-components';
import useContactPage from 'src/api/contact-page/use-contact-page';
import useTranslate from 'src/hooks/use-translate';

/**
 * Contansts.
 */

const currentYear = new Date().getFullYear();
const footerLogos = [{
  height: 58,
  images: [{
    src: '/static/images/apcer.png',
    title: 'APCER',
    width: 108
  }, {
    src: '/static/images/constructionline.png',
    title: 'Contructionline',
    width: 113
  }, {
    src: '/static/images/emas.png',
    title: 'EMAS',
    width: 38
  }]
}];

const prrLink = '/static/ficha-projeto-r2ut.pdf';
const visionPoliticStrategyInovationLink = '/static/visao-politica-estrategia-inovacao.pdf';
const prrLogos = [{
  images: [{
    height: 49,
    href: 'https://recuperarportugal.gov.pt',
    src: '/static/images/prr.png',
    title: 'PRR'
  }, {
    height: 41,
    href: 'https://recuperarportugal.gov.pt',
    src: '/static/images/republica.png',
    title: 'Républica Portuguesa'
  }, {
    height: 31,
    href: 'https://recuperarportugal.gov.pt',
    src: '/static/images/uniao-europeia.png',
    title: 'União Europeia'
  }],
  width: 120
}];

/**
 * `Section` styled component.
 */

const Section = styled.footer`
  background-color: ${color('black')};
  color: ${color('white')};
  padding: ${units(6)} 0 ${units(5)} 0;

  ${media.min('md')`
    padding: ${units(3.5)} 0 ${units(3)} 0;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'description'
    'logos'
    'prrWrapper'
    'financed'
    'social'
    'menu'
    'rights-reserved'
    'dst-logo';
  grid-template-columns: 100%;

  ${media.min('md')`
    grid-template-areas:
      'description description     description'
      'logos       logos           logos'
      'prrWrapper  prrWrapper      prrWrapper'
      'financed    social          menu'
      'dst-logo    rights-reserved rights-reserved';
    grid-template-columns: 4fr 3fr 5fr;
  `}
`;

/**
* `DescriptionCertifications` styled component.
*/

const DescriptionCertifications = styled.div`
  grid-area: description;
  margin-bottom: ${units(3.5)};

  ${media.min('md')`
    margin-bottom: ${units(2.5)};
    text-align: center;
  `}
`;

/**
 * `LogosFooter` styled component.
 */

const LogosFooter = styled.div`
  align-items: center;
  display: flex;
  grid-gap: ${units(2)} ${units(1.5)};
  margin-bottom: ${units(2.5)};

  ${media.min('md')`
    grid-gap: 0 ${units(1)};
    justify-content: center;
  `}
`;

/**
 * `FinancedWrapper` styled component.
 */

const FinancedWrapper = styled.div`
  border-top: 1px solid ${color.transparentize('grey', 0.3)};
  display: flex;
  flex-direction: column;
  grid-area: financed;
  grid-gap: ${units(1.5)} 0;
  margin-bottom: ${units(2)};
  padding-top: ${units(2.5)};

  ${media.min('md')`
    align-items: center;
    flex-direction: row;
    grid-gap: 0 ${units(1.5)};
  `}
`;

/**
 * `FooterLinksWrapper` styled component.
 */

const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  grid-area: menu;
  margin-bottom: ${units(2)};
  padding-top: ${units(2.5)};

  ${media.min('md')`
    align-items: center;
    border-top: 1px solid ${color.transparentize('grey', 0.3)};
    flex-direction: row;
    grid-gap: 0 ${units(0.5)};
    justify-content: end;
  `}
`;

/**
 * `FooterLink` styled component.
 */

const FooterLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalUrl(href) && RouterLink || 'a'
}))`
  ${theme('typography.styles.p')}

  color: ${color('white')};
  cursor: pointer;
  font-weight: 300;
  text-decoration: none;
  transition: opacity ${theme('animations.defaultTransition')};
  width: max-content;

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

/**
 * `ExternalLinksWrapper` styled component,
 */

const ExternalLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(1)};
  margin-bottom: ${units(2.5)};

  ${media.min('md')`
    align-items: center;
  `}
`;

/**
 * `ExternalLink` styled component.
 */

const ExternalLink = styled.a`
  ${theme('typography.styles.p')}

  color: ${color('white')};
  cursor: pointer;
  font-weight: 300;
  text-decoration: none;
  transition: opacity ${theme('animations.defaultTransition')};
  width: max-content;

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

/**
 * `SocialWrapper` styled component.
 */

const SocialWrapper = styled.div`
  grid-area: social;
  margin-bottom: ${units(2)};
  padding-top: ${units(2.5)};
  
  ${media.min('md')`
    align-items: center;
    display: flex;
    grid-column-gap: ${units(1.5)};
    border-top: 1px solid ${color.transparentize('grey', 0.3)};
    justify-content: center;
  `}
`;

/**
 * `SocialNetworksTitle` styled component.
 */

const SocialNetworksTitle = styled(Type.Paragraph)`
  margin-right: ${units(0.5)};

  ${media.max('md')`
    margin-bottom: ${units(1.5)};
  `}
`;

/**
 * `SocialLink` styled component.
 */

const SocialLink = styled.a`
  color: ${color('white')};
  cursor: pointer;
  display: inline-block;
  margin-right: ${units(1.5)};
  text-decoration: none;

  ${media.min('md')`
    display: flex;
    margin-right: 0;
  `}
`;

/**
 * `DstLink` styled component.
 */

const DstLink = styled.a`
  padding-top: ${units(2.5)};

  ${media.min('md')`
    border-top: 1px solid ${color.transparentize('grey', 0.3)};
  `}
`;

/**
 * `AllRightsReserved` styled component.
 */

const AllRightsReserved = styled.div`
  border-top: 1px solid ${color.transparentize('grey', 0.3)};
  grid-area: rights-reserved;
  margin-bottom: ${units(2)};
  padding-top: ${units(2.5)};

  ${media.min('md')`
    margin-bottom: 0;
    text-align: right;
  `}
`;

/**
 * `Footer` component.
 */

const Footer = (): ReactElement => {
  const { translate } = useTranslate();
  const { locale } = useRouter();
  const { data: dataContact } = useContactPage<ContactPage>();
  const isMobile = useBreakpoint('md', 'max');
  const menuFooterLinks = useMenuFooterLinks();
  const socialNetworks = useMemo(() => {
    return [{
      href: dataContact?.linkedin,
      icon: linkedinLogo,
      name: 'Linkedin'
    },
    {
      href: dataContact?.facebook,
      icon: facebookLogo,
      name: 'Facebook'
    }];
  }, [dataContact]);

  const socialNetworksHasSocial = size(socialNetworks.filter(data => data.href !== null)) > 0;

  return (
    <Section>
      <Container>
        <Grid>
          <DescriptionCertifications>
            <Type.Paragraph size={'medium'}>
              {translate('footer:descriptionCertifications')}
            </Type.Paragraph>
          </DescriptionCertifications>

          <Box gridArea={'logos'}>
            <LogosFooter>
              {footerLogos.map((items, index: number) => (
                <Fragment key={index}>
                  {items.images.map((image, indexImage: number) => (
                    <Image
                      alt={image.title}
                      height={items.height}
                      key={indexImage}
                      layout={'intrinsic'}
                      src={image.src}
                      width={image.width}
                    />
                  ))}
                </Fragment>
              ))}
            </LogosFooter>
          </Box>

          <Box gridArea={'prrWrapper'}>
            <Type.Paragraph
              marginBottom={units(1)}
              size={'medium'}
              textAlignMd={'center'}
            >
              {translate('footer:financed')}
            </Type.Paragraph>

            <LogosFooter>
              {prrLogos.map((items, index: number) => (
                <Fragment key={index}>
                  {items.images.map((image, indexImage: number) => (
                    <a
                      href={image.href}
                      key={indexImage}
                      rel={'noreferrer noopener'}
                      target={'_blank'}
                      title={image.title}
                    >
                      <Image
                        alt={image.title}
                        height={image.height}
                        layout={'intrinsic'}
                        src={image.src}
                        width={items.width}
                      />
                    </a>
                  ))}
                </Fragment>
              ))}
            </LogosFooter>
            
            <ExternalLinksWrapper>
              <ExternalLink
                href={prrLink}
                rel={'noreferrer noopener'}
                target={'_blank'}
                title={translate('footer:prrLinkLabel')}
              >
                {translate('footer:prrLinkLabel')}
              </ExternalLink>

              <ExternalLink
                href={visionPoliticStrategyInovationLink}
                rel={'noreferrer noopener'}
                target={'_blank'}
                title={translate('footer:visionPoliticStrategyInovationLinkLabel')}
              >
                {translate('footer:visionPoliticStrategyInovationLinkLabel')}
              </ExternalLink>
            </ExternalLinksWrapper>
          </Box>

          <FinancedWrapper>
            <Type.Paragraph size={'medium'}>
              {translate('footer:financed')}
            </Type.Paragraph>

            <Image
              alt={'COMPETE'}
              height={35}
              layout={'fixed'}
              src={`/static/images/compete.png`}
              width={179}
            />
          </FinancedWrapper>

          <FooterLinksWrapper>
            {menuFooterLinks.map((item, index: number) => (
              <Fragment key={index}>
                {!isMobile && (index > 0 ? '›' : '')}

                <FooterLink
                  href={item.href}
                  key={index}
                  {...item.href && !isExternalUrl(item.href) && { locale }}
                >
                  {translate(item.label)}
                </FooterLink>
              </Fragment>
            ))}
          </FooterLinksWrapper>

          <SocialWrapper>
            {socialNetworksHasSocial && (
              <>
                <SocialNetworksTitle size={'medium'}>
                  {translate('footer:socialNetworks')}
                </SocialNetworksTitle>

                {socialNetworks.filter(data => data.href !== null).map(({
                  href,
                  icon,
                  name
                }, index: number) => (
                  <Fragment key={index}>
                    <SocialLink
                      href={href}
                      rel={'noopener'}
                      target={'_blank'}
                      title={name}
                    >
                      <Svg
                        icon={icon}
                        size={units(3.5)}
                      />
                    </SocialLink>
                  </Fragment>
                ))}
              </>
            )}
          </SocialWrapper>

          <DstLink
            href={'https://www.dstsgps.com/'}
            rel={'noopener'}
            target={'_blank'}
            title={'dst group'}
          >
            <Svg
              icon={dstGroupLogo}
              size={units(16)}
            />
          </DstLink>

          <AllRightsReserved>
            <Type.Paragraph size={'medium'}>
              {translate('footer:allRightsReserved', { year: currentYear })}
            </Type.Paragraph>
          </AllRightsReserved>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `Footer` component.
 */

export default Footer;
