
/**
 * Module dependencies.
 */

import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';

/**
 * Export `useMenuFooterLinks`.
 */

export function useMenuFooterLinks() {
  const routeResolver = useLocalizedRoute();
  const menuFooterLinks = useMemo(() => {
    return [{
      href: routeResolver('termsConditions'),
      label: 'footer:links.labels.termsOfUse'
    }, {
      href: routeResolver('privacyPolicy'),
      label: 'footer:links.labels.privacyPolicy'
    }, {
      href: routeResolver('cookiesPolicy'),
      label: 'footer:links.labels.cookiesPolicy'
    }];
  }, [routeResolver]);

  return menuFooterLinks;
}
